export default {
    methods: {
        getAcceptedVariant(value) {
            if(value === true) {
                return 'success'
            }
            if(value === false) {
                return 'danger'
            }
            return 'secondary'
        },
        getAcceptedText(value) {
            if(value === true) {
                return 'Accepted'
            }
            if(value === false) {
                return 'Declined'
            }
            return 'Undecided'
        },
    }
}
