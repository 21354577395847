<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ state }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Students" :to="{ name: 'all-state-students' }" />
      <b-breadcrumb-item v-if="!state.loading && error" text="Student" active />
      <b-breadcrumb-item v-else-if="!state.loading && student" :text="`${student.name.first} ${student.name.last}`" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="all-state-student">
        <b-button v-if="state.editing" id="action-update" variant="primary" size="sm" class="d-inline-flex align-items-center" @click="updateStudent">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="mr-50" /> Update
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <template v-if="isEnabled">
        <b-dropdown-item id="option-edit" @click="toggleEdit(state)">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <can do="delete" on="all-state-student">
          <b-dropdown-item id="option-delete" @click="$refs.layout.confirmDelete(student, deleteStudent, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </template>
      <b-dropdown-item id="option-refresh" @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #tour="{ tourName }">
      <tour :name="tourName" :steps="tour.steps" :callbacks="tour.callbacks"/>
    </template>

    <template #content="{ state }">
      <template v-if="!isEnabled">
        <b-alert id="student-disabled" show variant="primary">
          <p> <b-icon-info-circle class="mr-25"/> Editing an Application is currently disabled.</p>
          <small>
            Applications have been disabled. Students are directly associated to an application, so they are also disabled. If you believe you should still be able to edit a student, please
            <b-link :to="{ name: 'support'}"><u>contact us</u></b-link> requesting temporary access.
          </small>
        </b-alert>
      </template>

      <template v-if="error">
        <b-alert id="student-error" show variant="danger">
          <p>
            <b-icon-exclamation-circle class="mr-25"/>
            {{ error }}
          </p>
        </b-alert>
      </template>
      <template v-else>
        <validation-observer id="student-wrapper" ref="observer" tag="div">
          <b-row>
            <b-col>
              <b-card id="details-card">
                <b-row>
                  <!-- Avatar -->
                  <b-col align-self="start" cols="auto">
                    <b-avatar variant="primary" size="6em" badge-variant="white">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                    </b-avatar>
                  </b-col>
                  <!-- Details -->
                  <b-col align-self="center">
                    <!-- Details - Name -->
                    <b-row>
                      <b-col align-self="start">
                        <validation-provider v-slot="validationContext" vid="legal-name" name="First Name" :rules="rules.firstName">
                          <b-form-group label="First Name" label-for="first-name-input" :invalid-feedback="validationContext.errors[0]">
                            <b-input id="first-name-input"
                                     v-model="student.name.first"
                                     :disabled="!state.editing"
                                     :state="getValidationState(validationContext)"/>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
											<b-col v-if="$can('manage', 'all-state-student')" align-self="start">
												<validation-provider v-slot="validationContext" vid="preferred-name" name="Preferred Name">
													<b-form-group label="Preferred Name" label-for="preferred-name-input" :invalid-feedback="validationContext.errors[0]">
														<b-input id="preferred-name-input"
                                v-model="student.name.preferred"
                                :disabled="!state.editing"
                                :state="getValidationState(validationContext)"/>
													</b-form-group>
												</validation-provider>
											</b-col>
                      <b-col align-self="start">
                        <validation-provider v-slot="validationContext" vid="last-name" name="Last Name" :rules="rules.lastName">
                          <b-form-group label="Last Name" label-for="last-name-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                            <b-input id="last-name-input"
                                     v-model="student.name.last"
                                     :disabled="!state.editing"/>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- Details - Other -->
                    <b-row>
                      <b-col md="4">
                        <validation-provider v-slot="validationContext" name="School" :rules="rules.school">
                          <b-form-group label="School" label-for="school-select" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                            <v-select v-model="student.school"
                                      input-id="school-select"
                                      :loading="options.schools.loading"
                                      :options="options.schools.items" label="name"
                                      :reduce="school => ({ id: school.id, name: school.name })"
                                      :filter="schoolSearch"
                                      :select-on-tab="true"
                                      :disabled="!state.editing"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      @open="onSchoolsOpen">
                              <template #option="{ name }">
                                <h6 class="mb-0">{{ name.legal }}</h6>
                                <small class="text-muted">{{ name.popular }}</small>
                              </template>
                              <template #selected-option="{ name }">
                                {{ name.legal }}
                              </template>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col align-self="start">
                        <validation-provider v-slot="validationContext" vid="grade" name="Grade" :rules="rules.grade">
                          <b-form-group label="Grade" label-for="grade-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                            <v-select v-model="student.grade"
                                      input-id="grade-input"
                                      :disabled="!state.editing"
                                      label="name"
                                      :options="options.grades"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      placeholder="Grade"
                                      class="w-100"/>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col align-self="start">
                        <validation-provider v-slot="validationContext" vid="dob" name="Date of Birth" :rules="rules.dob">
                          <b-form-group label="Date of Birth" label-for="dob-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                            <flat-pickr id="dob-input"
                                        ref="student-dob"
                                        v-model="student.dob"
                                        v-cleave="config.cleave"
                                        :config="config.pickr"
                                        :disabled="!state.editing"
                                        placeholder="MM/DD/YYYY"
                                        class="form-control"/>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
              <b-card id="address-card" title="Address">
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="line1" name="Line 1" :rules="rules.line1">
                      <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="line1-input"
                                      v-model="student.address.line1"
                                      :disabled="!state.editing"
                                      :state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="line2" name="Line 2" :rules="rules.line2">
                      <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="line2-input"
                                      v-model="student.address.line2"
                                      :disabled="!state.editing"
                                      :state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.city">
                      <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="city-input"
                                      v-model="student.address.city"
                                      :disabled="!state.editing"
                                      :state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="3">
                    <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.state">
                      <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                        <state-input id="state-input"
                                     v-model="student.address.state"
                                     placeholder="NY"
                                     :disabled="!state.editing"
                                     :validation-state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="3">
                    <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.zip">
                      <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                        <zip-input id="zip-code-input"
                                   v-model="student.address.zip"
                                   :disabled="!state.editing"
                                   :validation-state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
              <b-card id="contact-card" title="Contact">
                <b-row>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="phone" name="Phone" :rules="rules.phone">
                      <b-form-group :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <div class="d-flex justify-content-between">
                          <label for="phone-input">Cell Phone</label>
                          <b-icon-question-circle id="phone-help" />
                          <b-popover target="phone-help" triggers="hover" placement="left">
                            <small>Please enter a cell phone so that student's can be directly contacted by Ensemble Chairs and Chaperones.</small>
                          </b-popover>
                        </div>
                        <b-form-input id="phone-input"
                                      v-model="student.phone.number"
                                      v-mask="'(###) ###-####'"
                                      :disabled="!state.editing"
                                      :state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="email" name="Email" :rules="rules.email">
                      <b-form-group :invalid-feedback="validationContext.errors[0]">
                        <div class="d-flex justify-content-between">
                          <label for="email-input">Personal Email</label>
                          <b-icon-question-circle id="email-help" />
                          <b-popover target="email-help" triggers="hover" placement="left">
                            <small>
                              Please enter the student's personal email address. Some schools do not allow external email into their networks,
                              which would make it impossible to contact the student by email.
                            </small>
                          </b-popover>
                        </div>
                        <b-form-input id="email-input"
                                      v-model="student.email.address"
                                      :disabled="!state.editing"
                                      :state="getValidationState(validationContext)"/>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col class="col-xl-12 col-xxl-4">
              <!-- Teachers -->
              <b-card v-if="!isTeacher" id="teachers-card" no-body>
                <b-card-body>
                  <b-card-title class="mb-0">Teachers</b-card-title>
                </b-card-body>
                <template>
                  <b-list-group flush>
                    <template v-if="teachers.length">
                      <b-list-group-item v-for="teacher in teachers" :key="teacher.id" :to="$can('read', 'management-user') ? { name: 'management-user', params: { username: teacher.username } } : null">
                        <div class="d-flex justify-content-between align-items-center">
                          <b-media no-body>
                            <b-media-aside class="mr-1">
                              <b-avatar rounded variant="light-primary">
                                <font-awesome-icon icon="fa-solid fa-user" size="xl" />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h6 class="mb-0">
                                {{ teacher.name.first }} {{ teacher.name.last }}
                              </h6>
                              <small v-if="teacher.schools.items.length">Schools:
                                <span v-for="(item, index) in teacher.schools.items" :key="item.id" class="mr-25">
                                  {{ item.school.name.legal }}{{ index+1 !== teacher.schools.items.length ? ', ' : '' }}
                                </span>
                              </small>
                              <small v-else class="text-danger font-italic">No School Associations</small>
                            </b-media-body>
                          </b-media>
<!--                          <b-badge :variant="recommendationVariant(teacher.recommendation)">{{ recommendationText(teacher.recommendation) }}</b-badge>-->
                        </div>

                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item>
                      <span class="text-danger">
                         No Teachers associated to this student
                      </span>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </template>
              </b-card>
              <!-- Applications -->
              <b-card id="applications-card" no-body>
                <b-card-body>
                  <b-card-title class="mb-0">Applications</b-card-title>
                </b-card-body>
                <template v-if="isTeacher">
                  <template v-if="applications.length">
                    <b-list-group flush>
                      <b-list-group-item disabled class="font-small-3 pt-0">Submitted By Me</b-list-group-item>
                      <b-list-group-item v-for="application in applications" :key="application.id" v-b-toggle="`collapse-staff-${application.id}`">
                        <b-link :to="{ name: 'all-state-application', params: { id: application.id } }">
                          <div class="d-flex justify-content-between align-items-center">
                            <b-media no-body>
                              <b-media-aside class="mr-1">
                                <b-avatar rounded variant="light-primary">
                                  <font-awesome-icon icon="fa-solid fa-clipboard" size="2x" />
                                </b-avatar>
                              </b-media-aside>
                              <b-media-body class="my-auto">
                                <template>
                                  <h6 v-if="application.instrument" class="mb-0">
                                    {{ application.instrument.name }}
                                  </h6>
                                  <h6 v-else class="mb-0 text-danger">
                                    No Instrument
                                  </h6>
                                </template>
                                <template>
                                  <small v-if="application.festival" class="d-block">
                                    Festival: {{ application.festival.name }} {{ application.festival.site ? `- ${application.festival.site}` : null }}
                                  </small>
                                  <small v-else class="d-block text-danger">
                                    No Festival
                                  </small>
                                </template>

                              </b-media-body>
                            </b-media>
                            <b-badge :variant="recommendationVariant(application.recommendation)">{{ recommendationText(application.recommendation) }}</b-badge>
                          </div>
                        </b-link>
                      </b-list-group-item>
                    </b-list-group>

                    <b-list-group v-if="applications.filter(a => !a.teacherID.includes(user.id)).length" flush>
                      <b-list-group-item disabled class="font-small-3 border-top">Submitted By Others</b-list-group-item>
                      <b-list-group-item v-for="application in applications.filter(a => !a.teacherID.includes(user.id))" :key="application.id" v-b-toggle="`collapse-staff-${application.id}`">
                        <div class="d-flex justify-content-between align-items-center">
                          <b-media no-body>
                            <b-media-aside class="mr-1">
                              <b-avatar rounded variant="light-primary">
                                <font-awesome-icon icon="fa-solid fa-clipboard" size="2x" />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <template>
                                <h6 v-if="application.instrument" class="mb-0">
                                  {{ application.instrument.name }}
                                </h6>
                                <h6 v-else class="mb-0 text-danger">
                                  No Instrument
                                </h6>
                              </template>
                              <template>
                                <small v-if="application.festival" class="d-block">
                                  Festival: {{ application.festival.name }} {{ application.festival.site ? `- ${application.festival.site}` : null }}
                                </small>
                                <small v-else class="d-block text-danger">
                                  No Festival
                                </small>
                              </template>
                              <template>
                                <small v-if="application.teacher" class="d-block">
                                  Submitted By: {{ application.teacher.name.first }} {{ application.teacher.name.last }}
                                </small>
                                <small v-else class="d-block text-danger">
                                  Submitted By: No Teacher
                                </small>
                              </template>
                            </b-media-body>
                          </b-media>
                          <b-badge :variant="recommendationVariant(application.recommendation)">{{ recommendationText(application.recommendation) }}</b-badge>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </template>
                  <template v-else>
                    <b-list-group flush>
                      <b-list-group-item>
                        <span class="text-muted">
                           No applications associated to this student
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                  </template>
                </template>
                <template v-else>
                  <b-list-group flush>
                    <template v-if="applications.length">
                      <b-list-group-item v-for="application in applications" :key="application.id" :to="{ name: 'all-state-application', params: { id: application.id } }">
                        <div class="d-flex justify-content-between align-items-center">
                          <b-media no-body>
                            <b-media-aside class="mr-1">
                              <b-avatar rounded variant="light-primary">
                                <font-awesome-icon icon="fa-solid fa-clipboard" size="2x" />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <template>
                                <h6 v-if="application.instrument" class="mb-0">
                                  {{ application.instrument.name }}
                                </h6>
                                <h6 v-else class="mb-0 text-danger">
                                  No Instrument
                                </h6>
                              </template>
                              <template>
                                <small v-if="application.festival" class="d-block">
                                  Festival: {{ application.festival.name }} {{ application.festival.site ? `- ${application.festival.site}` : null }}
                                </small>
                                <small v-else class="d-block text-danger">
                                  No Festival
                                </small>
                              </template>
                              <template>
                                <small v-if="application.teacher" class="d-block">
                                  Teacher: {{ application.teacher.name.first }} {{ application.teacher.name.last }}
                                </small>
                                <small v-else class="d-block text-danger">
                                  No Teacher
                                </small>
                              </template>
                            </b-media-body>
                          </b-media>
                          <b-badge :variant="recommendationVariant(application.recommendation)">{{ recommendationText(application.recommendation) }}</b-badge>
                        </div>
                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item>
                      <span class="text-muted">
                         No applications associated to this student
                      </span>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </template>
              </b-card>
              <!-- Selections -->
              <b-card v-if="!isTeacher" id="selections-card" no-body>
                <b-card-body>
                  <b-card-title class="mb-0">Selections</b-card-title>
                </b-card-body>

                <b-list-group flush>
                  <template v-if="selections.length">
                    <b-list-group-item v-for="selection in selections" :key="selection.id"
                                       :to="{ name: 'all-state-selection', params: { slug: (selection.instrument ? selection.instrument.slug : 'no-instrument') }, query: { application: selection.selectionApplicationId } }">
                      <div class="d-flex justify-content-between align-items-center">
                        <b-media no-body>
                          <b-media-aside class="mr-1">
                            <b-avatar rounded variant="light-primary">
                              <font-awesome-icon icon="fa-solid fa-check-to-slot" size="2x" />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <template>
                              <h6 v-if="selection.instrument" class="mb-0">
                                {{ selection.instrument.name }}
                              </h6>
                              <h6 v-else class="mb-0 text-danger">
                                No Instrument
                              </h6>
                            </template>
                            <template>
                              <small v-if="selection.ensemble">{{ selection.ensemble.name }} | Part: {{ partText(selection.part) }}</small>
                              <small v-else class="text-danger">No Ensemble | Part: {{ partText(selection.part) }}</small>
                            </template>
                          </b-media-body>
                        </b-media>
                        <b-badge :variant="getAcceptedVariant(selection.accepted)">{{ getAcceptedText(selection.accepted) }}</b-badge>
                      </div>
                    </b-list-group-item>
                  </template>
                  <template v-else>
                    <b-list-group-item>
                      <span class="text-muted">
                         No selections associated to this student
                      </span>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </b-card>
              <!-- Invoices -->
							<b-card v-if="!isTeacher" id="invoice-card" no-body>
								<b-card-header>
									<b-card-title class="mb-0">Invoice</b-card-title>
								</b-card-header>

								<b-list-group v-if="isTeacher" flush>
									<template v-if="student.invoice && selections.some(selection => selection.accepted === true)"> <!--v-if="selections.some(selection => selection.accepted === true)"-->
										<b-list-group-item>
											<div class="d-flex justify-content-between align-items-center">
												<b-media no-body>
													<b-media-aside class="mr-1">
														<b-avatar rounded variant="light-primary">
															<font-awesome-icon icon="fa-solid fa-file-invoice-dollar" size="2x" />
														</b-avatar>
													</b-media-aside>
													<b-media-body class="my-auto">
														<h6 class="mb-0">
															{{ student.invoice.id }}
														</h6>
													</b-media-body>
												</b-media>
											</div>
										</b-list-group-item>
									</template>
									<template v-else>
										<b-list-group-item>
                    <span class="text-muted">
                       No invoice associated to this student
                    </span>
										</b-list-group-item>
									</template>
								</b-list-group>
								<b-list-group v-else flush>
									<template v-if="student.invoice"> <!--v-if="selections.some(selection => selection.accepted === true)"-->
										<b-list-group-item :to="{ name: 'management-invoice', params: { id: student.invoice.id} }">
											<div class="d-flex justify-content-between align-items-center">
												<b-media no-body>
													<b-media-aside class="mr-1">
														<b-avatar rounded variant="light-primary">
															<font-awesome-icon icon="fa-solid fa-file-invoice-dollar" size="2x" />
														</b-avatar>
													</b-media-aside>
													<b-media-body class="my-auto">
														<h6 class="mb-0">
															{{ student.invoice.id }}
														</h6>
													</b-media-body>
												</b-media>
											</div>
										</b-list-group-item>
									</template>
									<template v-else>
										<b-list-group-item>
                    <span class="text-muted">
                       No invoice associated to this student
                    </span>
										</b-list-group-item>
									</template>
								</b-list-group>
							</b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="4">
          <debug title="Settings" collapsed>{{ setting }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Student" collapsed>{{ student }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Selections (Computed)" collapsed>{{ selections }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import vSelect from 'vue-select'
import avatarMixin from '@/mixins/avatar.mixin'
import role from '@/mixins/role.mixin'
import status from '@/mixins/status.mixin'
import print from '@/mixins/print.mixin'
import notify from '@/mixins/notify.mixin'
import acceptance from '@/mixins/acceptance.mixin'
import {VueMaskDirective} from 'v-mask';
import Fuse from 'fuse.js';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import Cleave from 'cleave.js';
import flatPickr from 'vue-flatpickr-component';
import Tour from '@/components/Tour.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {
  getSetting,
  getStudent,
  getUser,
  listSchools,
  listZones,
  onUpdateSetting,
  updateStudent
} from '@/graphql/queries/student';
import {cascadeConfirmDeleteOptions, cascadeDeleteStudent} from '@/graphql/cascade/student';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'Student',
  directives: {
    mask: VueMaskDirective,
    cleave: {
      inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
      },
    }
  },
  components: {
    PageLayout,
    Tour,
    ZipInput,
    StateInput,
    AvatarModal,
    vSelect,
    flatPickr
  },
  mixins: [ acceptance, avatarMixin, role, status, print, notify, settingsMixin ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
  },
  data() {
    return {
      user: null,
      setting: null,
      student: null,
      icon: 'fas fa-graduation-cap',
      error: null,
      options: {
        grades: [10, 11],
        sex: ['Male', 'Female', 'Non-Binary'],
        schools: {
          items: [],
          loading: false
        }
      },
      rules: {
        school: { required: true },
        firstName: { required: true },
        lastName: { required: true },
        dob: {
          required: true,
          regex: /^(0[1-9]|1[012])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d\d$/
        },
        grade: { required: true },
        sex: { required: true },
        line1: { required: true },
        line2: { required: false },
        /* city: rules.address.city,
         state: rules.address.state,
         zip: rules.address.zip,*/
        city: {
          required: true,
          alpha_spaces: true,
          max: 48
        },
        state: {
          required: true,
          alpha: true,
          min: 2,
          max: 2
        },
        zip: {
          required: true,
          regex: /^\d{5}(?:[-\s]\d{4})?$/
        },
        phone: { required: true },
        email: { required: true, email: true }
      },
      config: {
        cleave: { date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' },
        pickr: { allowInput: true, clickOpens: false, dateFormat: 'm/d/Y'}
      },
      tour: {
        name: 'student',
        steps: [
          {
            target: '.breadcrumb',
            header: { title: 'Student View' },
            content: this.isEnabled ? `
                <p>The Student view page is meant as a way for the user to view an edit a Student.</p>
                <p>A Student is a single object, and shared across applications. Any changes to the Student will apply to all associated applications.</p>
                <p class="text-danger font-small-3">For the purpose of this tour, we've switched into Edit Mode.</p>
            ` : `
                <p>The Student view page is meant as a way for the user to view an edit a Student.</p>
                <p>A Student is a single object, and shared across applications. Any changes to the Student will apply to all associated applications.</p>
            `,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: false
            },
          },
          {
            target: '#details-card',
            header: { title: 'Student Details' },
            content: `
              The Student Details card contains demographic information about the student, as well as the School they are associated to.
            `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#address-card',
            header: { title: 'Student Address' },
            content: `
               <p>The Student Address card contains the home address information about the student.</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#contact-card',
            header: { title: 'Student Contact' },
            content: `
                <p>The Student Contact card contains ways to reach out to the student.</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#applications-card',
            header: { title: 'Applications' },
            content: 'The Applications card shows all the applications associated to the Student.',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#selections-card',
            header: { title: 'Selections' },
            content: `
                <p>The Selections card shows all the applications associated to the Student that have been selected into an ensemble.</p>
              `,
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#action-update',
            header: { title: 'Update' },
            content: `
                <p>Once you've finished making edits, click this button to save your changes.</p>
            `,
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
          },
        ],
        callbacks: {
          onStart: () => {
            this.$refs.layout.state.editing = this.isEnabled
          },
          onSkip: () => {
            this.$refs.layout.state.editing = false
          },
          onFinish: () => {
            this.$refs.layout.state.editing = false
          },
          onStop: () => {
            this.$refs.layout.state.editing = false
          },
        },
        props: { }
      },
      subscriptions: {
        onUpdate: null
      },
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    counties() {
      return this.student.zone ? this.options.zones.find(zone => zone.id === this.student.zone.id).counties : []
    },
    teachers() {
      const teachers = this.student.applications.items.filter(item => item?.teacher?.id).map(application => application.teacher);
      return Array.from(teachers.reduce((acc, teacher) => {
        const uniqueIdentifier = teacher.id; // or `${teacher.name.first} ${teacher.name.last}` if ID is not available
        acc.set(uniqueIdentifier, teacher);
        return acc;
      }, new Map()).values());
    },
    applications() {
      return this.student?.applications?.items?.slice().sort((a, b) => a.createdAt.localeCompare(b.createdAt)) || []
    },
    selections() {
      const selections = []
      if(this.student) {
        this.student.applications.items.forEach(application => {
          if(application.selection) {
            application.selection.instrument = application.instrument
            selections.push(application.selection)
          }
        })
      }
      return selections
    },
    isTeacher() {
      return this.user?.groups.includes('Teacher')
    },
    isEnabled() {
      return this.setting?.enabled
          || this.setting?.override?.groups?.some(group => this.user.groups.includes(group))
          || this.setting?.override?.users?.some(user => user.id === this.user.id)
    },
  },
  watch: {
    isEnabled(value) {
      if(this.$refs.layout.state.editing && !value) {
        this.$refs.layout.state.editing = false
      }
    }
  },
  async mounted() {
    await this.getCurrentUser()
    await this.getSetting()
    await this.addConditionalTourSteps()
    await this.getStudent();
    await this.onUpdateSetting()
  },
  beforeDestroy() {
    if(this.subscriptions?.onUpdate) {
      this.subscriptions.onUpdate.unsubscribe()
    }
  },
  methods: {
    /** User **/
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()
      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },

    /** Setting **/
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'application' }));
      const setting = response.data.getSetting
      if(setting) {
        this.setting = JSON.parse(setting.value)
      }
    },
    async onUpdateSetting() {
      this.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateSetting)).subscribe((sourceData) => {
        const setting = sourceData.value.data.onUpdateSetting
        if (setting?.key === 'application') {
          this.syncNotification()
          this.setting = JSON.parse(setting.value)
        }
      });
    },

    /** Student **/
    async getStudent() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getStudent, { id: this.id }));
        this.student = response.data.getStudent
        if(this.student) {
          /** Check Current Year **/
          if(!this.isCreatedAtInCurrentYear(this.student.createdAt)) {
            this.error = `You are currently viewing ${this.$store.state.settings.app.current.title} data. This student was created in ${new Date(this.student.createdAt).getFullYear()}. Switch to that year to view this student.`
          }

          /** Check Permissions **/
          if (this.user.groups.includes('Teacher')) {
            const hasError = !this.student.applications.items.map(application => application.teacherID).includes(this.user.id)
            if(hasError) { this.error = 'You don\'t have permission to manage this student.' }
          }
        }
        else {
          this.error = 'Student Doesn\'t Exist'
        }
        this.$refs.layout.state.loading = false
      }
    },
    async patchStudent(input) {
      try {
        await API.graphql(graphqlOperation(updateStudent, { input: input } ));
        this.notify({ title: 'Success', text: 'Student was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Student failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateStudent() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchStudent({
          id: this.student.id,
          name: this.student.name,
          grade: this.student.grade,
          dob: this.student.dob,
          /*sex: this.student.sex,*/
          address: this.student.address,
          phone: this.student.phone,
          email: this.student.email,
          schoolID: this.student.school.id
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'Student failed to update. Missing required fields.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteStudent(student, swalCallback) {
      try {
        await this.cascadeDeleteStudent(student.id, swalCallback)
        await this.notify({ title: 'Success', text: 'Student was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'all-state-students' })
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Student failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteStudent,

    /** Options **/
    async listZones() {
      const response = await API.graphql(graphqlOperation(listZones));
      this.options.zones = response.data.listZones.items;
    },
    async listSchools(nextToken, pagedSchools) {
      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
      schools.push(...response.data.listSchools.items);

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.options.schools.items = schools.sort((a, b) => a.name.legal.localeCompare(b.name.legal))
        this.options.schools.loading = false
      }
    },

    /** Tour **/
    async addConditionalTourSteps() {
      if(!this.isEnabled) {
        this.tour.steps.splice(0, 0, {
          target: '#student-disabled',
          header: { title: 'Disabled' },
          content: `
                <p>The Application view page is meant as a way for the user to view an edit an application that was previously submitted.</p>
                <p>The contents of this page are the same as creating a new application, it's just displayed differently to help differentiate what action is being performed.</p>
                <p class="text-danger font-small-3">For the purpose of this tour, we've switched into Edit Mode.</p>
              `,
          params: {
            placement: 'auto',
            enableScrolling: false,
            highlight: true
          },
        })
        this.tour.steps.splice(-1) //remove updating step
      }
      if(this.error) {
        this.tour.steps = []
        this.tour.steps.push({
          target: '#student-error',
          header: { title: 'Error' },
          content: `
                <p>The Application view page is meant as a way for the user to view an edit an application that was previously submitted.</p>
                <p>The contents of this page are the same as creating a new application, it's just displayed differently to help differentiate what action is being performed.</p>
                <p class="text-danger font-small-3">For the purpose of this tour, we've switched into Edit Mode.</p>
              `,
          params: {
            placement: 'auto',
            enableScrolling: false,
            highlight: true
          },
        })
      }
    },

    /** UI **/
    async refresh() {
      this.$refs.layout.state.loading = true
      this.error = null
      await this.getSetting()
      await this.getStudent()
      this.$refs.layout.state.loading = false
    },
    async onSchoolsOpen() {
      if (this.options.schools.items.length === 0) {
        this.options.schools.loading = true

        if (this.user.groups.includes('Teacher')) {
          this.options.schools.items.push(...this.user.schools.items.map(item => item.school).sort((a, b) => a.name.legal.localeCompare(b.name.legal)))
        }
        else {
            await this.listSchools()
        }
      }
      this.options.schools.loading = false
      return this.options.schools.items
    },
    schoolSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    recommendationText(recommendation) {
      if(recommendation === 'I recommend Highly') {
        return 'Highly Recommended'
      }
      if(recommendation === 'I recommend') {
        return 'Recommended'
      }
      if(recommendation === 'I recommend with Reservation') {
        return 'Recommended with Reservation'
      }
      if(recommendation === 'I do not recommend') {
        return 'Not recommend'
      }
      return recommendation
    },
    recommendationVariant(recommendation) {
      if(recommendation === 'I recommend Highly') {
        return 'success'
      }
      if(recommendation === 'I recommend') {
        return 'info'
      }
      if(recommendation === 'I recommend with Reservation') {
        return 'warning'
      }
      if(recommendation === 'I do not recommend') {
        return 'danger'
      }
      return 'dark'
    },
    partText(part) {
      if(part === null) {
        return 'Unassigned'
      }
      return part
    },
    partVariant(part) {
      if(part === null) {
        return 'danger'
      }
      if(part === 'Alternate') {
        return 'secondary'
      }
      return 'success'
    },
    toggleEdit(state) {
      state.editing = !state.editing
      if(state.editing) {
        this.$refs.observer.reset()
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  }
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-flatpicker.scss';
  @import '~@core/scss/vue/libs/vue-select.scss';

  .per-page-selector {
    min-width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>
