// eslint-disable-next-line import/prefer-default-export
export const getStudent = /* GraphQL */ `
    query GetStudent($id: ID!) {
        getStudent(id: $id) {
            id
            name {
                first
                preferred
                last
            }
            dob
            sex
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            phone {
                type
                number
                ext
            }
            email {
                type
                address
            }
            grade
            school {
                id
                name {
                    legal
                    popular
                }
            }
            applications {
                items {
                    id
                    festival {
                        name
                        site
                    }
                    instrument {
                        name
                        slug
                    }
                    selection {
                        id
                        part
                        ensemble {
                            name
                        }
                        accepted
                        selectionApplicationId
                    }
                    teacherID
                    teacher {
                        id
                        username
                        name {
                            first
                            last
                        }
                        schools {
                            items {
                                school {
                                    id
                                    name {
                                        legal
                                    }
                                }
                            }
                        }
                    }
                    recommendation
                    createdAt
                }
            }
            invoice {
                id
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateStudent = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            name {
                first
                last
            }
            grade
            school {
                name {
                    legal
                }
            }
            applications {
                items {
                    id
                    teacherID
                }
            }
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
            }
            nextToken
        }
    }
`;
export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
        }
    }
`;

export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;
export const onUpdateSetting = /* GraphQL */ `
    subscription OnUpdateSetting {
        onUpdateSetting {
            key
            value
        }
    }
`;

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            schools {
                items {
                    school {
                        id
                        name {
                            legal
                            popular
                        }
                    }
                }
            }
        }
    }
`;

