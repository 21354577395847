/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {cascadeDeleteApplication} from '@/graphql/cascade/application';
import {cascadeDeleteInvoice} from '@/graphql/cascade/invoice';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Student may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Applications, Scores, Selections, Invoices, and Payments associated with this Student will be deleted.<br/>
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Student was successfully deleted.' }
}

export async function cascadeDeleteStudent(studentId, swalCallback) {
    try {
        /** Delete Applications associated to this Student
         * Note that this is a recursive call to the cascadeDeleteApplication function which will delete the Application
         * record and update all Selection records associated to the Application (removing the connection). This will
         * also trigger cascadeDeleteSelection which will delete the Selection record and update all Application records
         * associated to the Selection (removing the connection).
         **/
        const applications = await listApplications(studentId, swalCallback)
        await batchProcessWithRetry(applications, async (application) => {
            await cascadeDeleteApplication(application.id, swalCallback)
        }, { title: 'Deleting Applications', callback: swalCallback })


        /** Delete StudentEvent associated to this Student **/
        const studentEvents = await listStudentEventsByStudentId(studentId, swalCallback)
        await batchProcessWithRetry(studentEvents, async (studentEvent) => {
            await deleteStudentEvent(studentEvent.id, swalCallback)
        }, { title: 'Deleting Student Events', callback: swalCallback })

        /** Delete Invoices associated to this Student
         * Note that this is a recursive call to the cascadeDeleteInvoice function
         * which will delete the Invoice record and delete all Payment records associated to the Invoice.
         **/
        const invoices = await listInvoices(studentId, swalCallback)
        await batchProcessWithRetry(invoices, async (invoice) => {
            await cascadeDeleteInvoice(invoice.id, swalCallback)
        }, { title: 'Deleting Invoices', callback: swalCallback })

        /** Delete Student **/
        await deleteStudent(studentId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteStudent(studentId, swalCallback) {
    const title = 'Deleting Student'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteStudentMutation, { input: { id: studentId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function deleteStudentEvent(studentId, swalCallback) {
    const title = 'Deleting Student Event'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteStudentEventMutation, { input: { id: studentId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listApplications(studentId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { studentApplicationsId: { eq: studentId } } }
            const response = await API.graphql(graphqlOperation(listApplicationsQuery, input));
            items.push(...response.data.listApplications.items)
            nextToken = response.data.listApplications.nextToken;
            swalCallback(new SwalData('Loading Applications', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

async function listInvoices(studentId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { invoiceStudentId: { eq: studentId } } }
            const response = await API.graphql(graphqlOperation(listInvoicesQuery, input));
            items.push(...response.data.listInvoices.items)
            nextToken = response.data.listInvoices.nextToken;
            swalCallback(new SwalData('Loading Invoices', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}


async function listStudentEventsByStudentId(studentId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, studentId: studentId }
            const response = await API.graphql(graphqlOperation(listStudentEventsByStudentQuery, input));
            items.push(...response.data.listStudentEventsByStudent.items)
            nextToken = response.data.listStudentEventsByStudent.nextToken;
            swalCallback(new SwalData('Loading Student Events', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listApplicationsQuery = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                studentApplicationsId
            }
            nextToken
            __typename
        }
    }
`;

const listInvoicesQuery = /* GraphQL */ `
    query ListInvoices(
        $id: ID
        $filter: ModelInvoiceFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInvoices(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                invoiceStudentId
            }
            nextToken
        }
    }
`;

const listStudentEventsByStudentQuery = /* GraphQL */ `
    query ListStudentEventsByStudent(
        $studentId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentEventsByStudent(
            studentId: $studentId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                studentId
            }
            nextToken
        }
    }
`;

const deleteStudentMutation = /* GraphQL */ `
    mutation DeleteStudent(
        $input: DeleteStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        deleteStudent(input: $input, condition: $condition) {
            id
        }
    }
`;

const deleteStudentEventMutation = /* GraphQL */ `
    mutation DeleteStudentEvent(
        $input: DeleteStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        deleteStudentEvent(input: $input, condition: $condition) {
            id
        }
    }
`;


